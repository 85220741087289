



































































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import fuzzysort from 'fuzzysort';

@Component
export default class Dropdown extends Vue {
//   @Model('change', { type: Array }) readonly dropdownValues!: any
//   @Prop({ type: Array, default: [] }) options!: any;
//   @Prop({ type: Boolean, default: false }) multiselect!: boolean;
//   @Prop({ type: Array, default: [] }) selectionLocation!: any;

  searchDropdownInput = ''

  created() {

  }


//   get filteredOptions() {
	//   if (this.searchDropdownInput.length == 0) {
	// 	  return this.options
	//   }
	// 	var results = []
	// 	const fuzzysort_results = fuzzysort.go(this.searchDropdownInput, this.options)
		
	// 	for (var x = 0; x < fuzzysort_results.length; x++) {
	// 		// @ts-ignore
	// 		results.push(fuzzysort_results[x].target)
	// 	}
	//   return results
//   }



  hideDropdown() {
	  this.$emit('hide-dropdown')
  }

//   addValToDropdown(val) {
// 	  if (!this.multiselect) {
// 		  while (this.dropdownValues.length) { this.dropdownValues.pop(); }
// 		  this.dropdownValues.push(val)
// 		  this.$emit('change', [val])
// 	  } else {
// 		if (this.dropdownValues.includes(val)) {
// 			const index = this.dropdownValues.indexOf(val);
// 			if (index > -1) {
// 				this.dropdownValues.splice(index, 1);
// 			}
// 		} else {
// 			this.dropdownValues.push(val)
// 		}
// 	  }
// 	//   this.dropdownValues.push(val)
// 	  this.$emit('change', this.dropdownValues)
//   }
}
